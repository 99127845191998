import styles from './styles.module.css';
import BannerCaja from '@/widgets/Banner/Templates/BannerCaja';
const PISO1NOTASBannerLoUltimo = (props) => {
console.log(props);
    return (
          
        <div className={`${styles["Piso1Notas-parent"]}`}>
            <div className={`${styles['primer-bloque']}`}>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(1, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(2, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(3, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(4, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(5, "NotaContainerGrilla")}</div>
                <div className={` ${styles["grid-item"]}`}>{props.renderContainerWidgetCallback(6, "NotaContainerGrilla")}</div>
            </div>
            <div className={`${styles['segundo-bloque']}`}>
                <BannerCaja bannerId='caja1'/>
                <div className={styles['grid-item2']}>{props.renderContainerWidgetCallback(7, "LoUltimoVertical")}</div>
            </div>                
        </div>
        
    )
}

export default PISO1NOTASBannerLoUltimo;
