import { AppWrapper } from '@pagegrowler_nextjs';
import '../styles.css';
import '../config.css';
import { pagegrowlerConfigFrontend } from "../pagegrowler.config";
import widgets from '@/widgets/index'
import templates from '@/templates/index'
import Script from "next/script";


import { useEffect } from 'react';


function Application({ Component, pageProps }) {

  /* Se importan los widgets desde la aplicacion padre hacia la libreria */
  pageProps.widgets = widgets;
  pageProps.templates = templates;
  pageProps.lila_url = process.env.NEXT_PUBLIC_LILAX_URL;
  pageProps.pagegrowler_backend_url = process.env.NEXT_PUBLIC_PAGEGROWLER_BACKEND_URL;
  pageProps.pagegrowler_frontend_api_url = '';

  pageProps.matchConfig = pageProps.matchConfig || { query: {} }
  pageProps.externalConfig = pagegrowlerConfigFrontend;
  
  // Google Tag Manager
  useEffect(() => {
    window.OneSignal = window.OneSignal || [];
    OneSignal.push(function () {
        OneSignal.init({
            appId: "66b8d013-5ef9-45a1-aee0-3ef799a77aa2",
            notifyButton: {
                enable: true,
            },
        });
    });

    return () => {
        window.OneSignal = undefined;
    };
}, [])
  return (
    <>
      <Script strategy="afterInteractive" crossOrigin src="https://connect.facebook.net/es_LA/sdk.js#xfbml=1&version=v13.0" nonce="M21eD7Vg" />
      <Script strategy="afterInteractive" src="https://securepubads.g.doubleclick.net/tag/js/gpt.js" />

      <Script strategy="afterInteractive" src="https://s1.adzonestatic.com/c/29_ellitoral-2021.js" />
      <Script strategy="afterInteractive" src="https://cdn.onesignal.com/sdks/OneSignalSDK.js" />
      
      <Script strategy="afterInteractive" id="beacon-js">
        {`
          var _comscore = _comscore || [];
          _comscore.push({ c1: "2", c2: "6906430",options: {
      enableFirstPartyCookie: true,
      bypassUserConsentRequirementFor1PCookie: true
    }});
          (function() {
            var s = document.createElement("script"), el = document.getElementsByTagName("script")[0]; s.async = true;
            s.src = "https://sb.scorecardresearch.com/cs/6906430/beacon.js";
            el.parentNode.insertBefore(s, el);
          })();
        `}
      </Script>
   
      <AppWrapper>
        <Component {...pageProps} />
      </AppWrapper>
    </>
  )
}
export default Application
