import dynamic from "next/dynamic";
import PISO1NOTASBannerLoUltimo from "./Container/Templates/PISO1NOTASBannerLoUltimo";

const templates = {
  Autores: dynamic(() => import("./Listado/Templates/Autores")),
  LoMasVisto: dynamic(() => import("./Listado/Templates/LoMasVisto")),
  LoMasVistoBuckets: dynamic(() => import("./Listado/Templates/LoMasVistoBuckets")),
  TePuedeInteresar: dynamic(() => import("./Listado/Templates/TePuedeInteresar")),
  AdemasTenes: dynamic(() => import("./Listado/Templates/AdemasTenes")),
  LoUltimo: dynamic(() => import("./Listado/Templates/LoUltimo")),
  LoUltimoVertical: dynamic(() => import("./Listado/Templates/LoUltimoVertical")),
  TenesQueSaber: dynamic(() => import("./Listado/Templates/TenesQueSaber")),
  TemaEspecial: dynamic(() => import("./Listado/Templates/TemaEspecial")),
  NotasDeSeccion: dynamic(() => import("./Listado/Templates/NotasDeSeccion")),
  NotasDeAutor: dynamic(() => import("./Listado/Templates/NotasDeAutor")),
  ListadoDestacado: dynamic(() => import("./Listado/Templates/ListadoDestacado")),
  Opinion: dynamic(() => import("./Listado/Templates/Opinion")),
  Destacadas: dynamic(() => import("./Listado/Templates/Destacadas")),
  Opinion: dynamic(() => import("./Listado/Templates/Opinion")),
  Multimedia: dynamic(() => import("./Listado/Templates/Multimedia")),
  Destacada: dynamic(() => import("./Nota/Templates/Destacada")),
  Especial: dynamic(() => import("./Nota/Templates/Especial")),
  DestacadaXL: dynamic(() => import("./Nota/Templates/DestacadaXL")),
  Alerta: dynamic(() => import("./Nota/Templates/Alerta")),
  NotasDePortada: dynamic(() => import("./Listado/Templates/NotasDePortada")),
  RichText: dynamic(() => import('./Misc/Templates/RichText')),
  Freehtml: dynamic(() => import('./Misc/Templates/Freehtml')),
  Clima: dynamic(() => import('./Misc/Templates/Clima')),
  BannerCaja: dynamic(() => import('./Banner/Templates/BannerCaja')),
  BannerHorizontalGrande: dynamic(() => import('./Banner/Templates/BannerHorizontalGrande')),
  Suscribite: dynamic(() => import('./Misc/Templates/Suscribite')),
  Titulo: dynamic(() => import('./Misc/Templates/Titulo')),
  BannerCajaGrande : dynamic(() => import('./Banner/Templates/BannerCajaGrande')),
  BannerHorizontalChico : dynamic(() => import('./Banner/Templates/BannerHorizontalChico')),
  BannerInline : dynamic(() => import('./Banner/Templates/BannerInline')),
  GoogleSearchBar: dynamic(() => import('./Misc/Templates/GoogleSearchBar')),
  ListadoDestacadoBuckets: dynamic(() => import('./Listado/Templates/ListadoDestacadoBuckets')),
  NotasDePortadaBuckets: dynamic(() => import('./Listado/Templates/NotasDePortadaBuckets')),
  GaleriasDePortada: dynamic(() => import('./Listado/Templates/GaleriasDePortada')),
  UltimasDeSecciones: dynamic(() => import('./Listado/Templates/UltimasDeSecciones')),
  VideoLive: dynamic(() => import('./Misc/Templates/VideoLive')),
  VideoNota: dynamic(() => import('./Misc/Templates/VideoNota')),
  DestacadoEspecial: dynamic(() => import('./Misc/Templates/DestacadoEspecial')),
  MinAMinNota: dynamic(() => import('./Misc/Templates/MinAMinNota')),
  MinAMinDoble: dynamic(() => import('./Misc/Templates/MinAMinDoble')),
  MinAMinManual: dynamic(() => import('./Misc/Templates/MinAMinManual')),
  NotasConBanner: dynamic(() => import('./Listado/Templates/NotasConBanner')),
  AperturaSinFoto: dynamic(() => import('./Listado/Templates/AperturaSinFoto')),
  HTMLLibreV2: dynamic(() => import('./Misc/Templates/HTMLLibreV2')),
  AdemasTenesQueLeer: dynamic(() => import('./Listado/Templates/AdemasTenesQueLeer')),
  SintesisDia: dynamic(() => import('./Misc/Templates/SintesisDia')),
  PagegrowlerList: dynamic(() => import('./Listado/Templates/PagegrowlerList')),
  CarouselDeGalerias: dynamic(() => import('./Listado/Templates/CarouselDeGalerias')),
  CarouselDeVideos: dynamic(() => import('./Listado/Templates/CarouselDeVideos')),
  ListaVideoVertical: dynamic(() => import('./Listado/Templates/ListaVideoVertical')),
  ModuloContainerOpinion: dynamic(() => import('./Nota/Templates/ModuloContainerOpinion')),
  ModuloContainerOpinionNotas: dynamic(() => import('./Nota/Templates/ModuloContainerOpinionNotas')),
  DestacadaContainerOpinion: dynamic(() => import('./Nota/Templates/DestacadaContainerOpinion')),
  NotaAperturaHorizontal: dynamic(() => import('./Nota/Templates/NotaAperturaHorizontal')),
  NotaHorizontalDerecha: dynamic(() => import('./Nota/Templates/NotaHorizontalDerecha')),
  NotaAperturaHorizontal2: dynamic(() => import('./Nota/Templates/NotaAperturaHorizontal2')),
  DestacadaAperturaInContainers: dynamic(() => import('./Nota/Templates/DestacadaAperturaInContainers')),
  DestacadaAperturaInContainersBuckets: dynamic(() => import('./Nota/Templates/DestacadaAperturaInContainersBuckets')),
  ModuloDestacadoXL: dynamic(() => import('./Nota/Templates/ModuloDestacadoXL')),
  NotaAperturaBullet: dynamic(() => import('./Nota/Templates/NotaAperturaBullet')),

  FreeIframe: dynamic(() => import('./Misc/Templates/FreeIframe')),

  // CONTAINERS
  AperturaContainerXL: dynamic(() => import('./Container/Templates/AperturaContainerXL')),
  AperturaContainerNotas: dynamic(() => import('./Container/Templates/AperturaContainerNotas')),
  OpinionContainer: dynamic(() => import('./Container/Templates/OpinionContainer')),
  OpinionContainerNotas: dynamic(() => import('./Container/Templates/OpinionContainerNotas')),
  PrimerModuloDestacado: dynamic(() => import('./Nota/Templates/PrimerModuloDestacado')),
  SegundoModuloDestacado: dynamic(() => import('./Nota/Templates/SegundoModuloDestacado')),
  DestacadoContainer: dynamic(() => import('./Container/Templates/DestacadoContainer')),
  NotaContainer5: dynamic(() => import('./Container/Templates/NotaContainer5')),
  SegundoModuloDeNotaContainer: dynamic(() => import('./Nota/Templates/SegundoModuloDeNotaContainer')),
  PrimerModuloDeNotaContainer: dynamic(() => import('./Nota/Templates/PrimerModuloDeNotaContainer')),
  WidgetContainerEjemplo: dynamic(() => import('./Container/Templates/WidgetContainerEjemplo')),
  CONTAINER2Pisos7: dynamic(() => import('./Container/Templates/CONTAINER2Pisos7')),
  ModuloContainerGrilla: dynamic(() => import('./Nota/Templates/ModuloContainerGrilla')),
  Apertura2Notas: dynamic(() => import('./Container/Templates/Apertura2Notas')),
  AperturaContainerDestacado: dynamic(() => import('./Container/Templates/AperturaContainerDestacado')),
  DestacadaInContainers: dynamic(() => import('./Nota/Templates/DestacadaInContainers')),
  PISO1NOTASBannerLoUltimo: dynamic(() => import('./Container/Templates/PISO1NOTASBannerLoUltimo')),
  NotaContainerGrilla: dynamic(() => import('./Nota/Templates/NotaContainerGrilla')),
  PisoNotasGenericas: dynamic(() => import('./Container/Templates/PisoNotasGenericas')),
  NotaContainerGrilla100Porciento: dynamic(() => import('./Nota/Templates/NotaContainerGrilla100Porciento')),
  PISO2NOTASBanner: dynamic(() => import('./Container/Templates/PISO2NOTASBanner')),
  PISO3NOTASLoMasVisto: dynamic(() => import('./Container/Templates/PISO3NOTASLoMasVisto')),
  PISO4NOTASBarra: dynamic(() => import('./Container/Templates/PISO4NOTASBarra')),
  PISOSECCIONLoMasVisto: dynamic(() => import('./Container/Templates/PISOSECCIONLoMasVisto')),
  PISOSECCIONGenericas: dynamic(() => import('./Container/Templates/PISOSECCIONGenericas')),
  AperturaContainerDestacadoBullets: dynamic(() => import('./Container/Templates/AperturaContainerDestacadoBullets'))
};

export default templates;
